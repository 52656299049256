:root {
	--primary: #285D9F;
    --secondary: #E93D3D;
	--white: #fff;
	--font-14: 14px;
}
.br-40 {
    border-radius: 40px;
}
.main-content .container-fluid {
    padding: 0;
}
.welcome-section .two-col-grid {
    margin: 40px auto;
}
.welcome-section .row-description-wrapper p {
    font-weight: 400;
}
.welcome-section .grid-child .images__wrapper img {
    border-radius: 40px;
}
.our-services, .why-choose-us {
    padding: 20px 0;
}
.flip-box-front .image {
    width: 100%;
    max-width: 100%;
    float: left;
    position: relative;
    margin-bottom: 30px;
}
.flip-box-front .f-title {
    font-size: 20px;
    font-weight: 600;
    word-break: break-word;
    padding: 10px;
    display: block;
    overflow: hidden;
    color: #fff;
    text-align: center;
    line-height: normal;
}
.flip-box {
    background-color: transparent;
    height: 290px;
    perspective: 1000px;
}
.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
    backface-visibility: hidden;
    
}

.flip-box:hover .flip-box-inner {
  transform: rotateX(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 4px, rgb(10, 90, 170) 0px 0px 0px 6px;
  background-color: var(--primary);
  padding: 1em;
  border-radius: 40px;
}
.flip-box-back {
    background-color: var(--primary);
    color: #fff;
    transform: rotateX(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
}
.back-content a {
    text-align: center;
    padding: 3px 11px;
    display: inline-block;
    background-color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    border-radius: 40px;
    word-break: break-word;
    margin-top: 10px;
    text-decoration: none;
}
.back-content a:hover {
    transform: scale(1.1);
}
.flip-box-area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 40px;
    padding: 40px 0;
}
.flip-box-area .flex-box-col {
    margin: 6px;
}
.why-slider-section .item .title {
    text-align: center;
    font-weight: 700;
    margin: 20px 0;
    line-height: normal;
}
.why-slider-section .owl-nav button.owl-next {
    background: rgba(0, 0, 0, 0.3) url(../images/icon_next.png) no-repeat center center !important;
    right: 0;
    margin: 0;
}
.why-slider-section .owl-nav button.owl-prev {
    background: rgba(0, 0, 0, 0.3) url(../images/icon_prev.png) no-repeat center center !important;
    left: 0;
    margin: 0;
}
.why-slider-section .owl-nav button.owl-prev, .why-slider-section .owl-nav button.owl-next {
    position: absolute;
    top: 50%;
    padding: 2px 0px !important;
    display: inline-block;
    margin-top: -40px !important;
    line-height: 30px !important;
    height: 40px !important;
    width: 30px !important;
    transition: all .3s ease-in-out !important;
    color: inherit;
    border: none;
    padding: 0 !important;
}
.why-slider-section .owl-nav button.owl-prev:hover, .why-slider-section .owl-nav button.owl-next:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.why-slider-section .owl-nav button span {
    display: none;
}
.why-slider-section .owl-nav {
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    align-items: center;
    width: 100%;
}
.why-slider-section {
    padding-top: 40px;
    padding-bottom: 30px;
}
.our-sections-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding-bottom: 40px;
    padding-top: 20px;
}
.our-sections-grid .grid-box .image {
    margin: 30px 0;
}
.our-sections-grid .grid-box .content p {
    text-align: justify;
    line-height: 28px;
}
.our-sections-grid .grid-box .content li {
    line-height: 28px;
}
.top-footer .button-box .wrapper {
    padding: 38px 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
}
.top-footer .button-box .wrapper .btn-contact {
    display: inline-flex;
    margin: 0 auto;
    height: 100%;
    align-items: center;
}

@media (max-width: 991px) {
    .flip-box-area {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 25px;
        padding: 40px 0;
        max-width: 360px;
        margin: 0 auto;
    }

    #navbarNavDropdown {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 999;
        top: 80px;
    }
    #navbarNavDropdown #menu-content {
        padding: 20px 0;
    }
    #navbarNavDropdown #menu-content li a.nav-link {
        line-height: 20px;
        padding: 10px;
    }
}

@media (max-width: 767px) {
    .welcome-section, .our-services, .our-sections, .why-choose-us {
        padding: 0 15px;
    }
    .our-sections-grid {
        grid-template-columns: 100%;
        grid-gap: 25px;
        max-width: 360px;
        margin: 0 auto;
    }
    .top-footer .button-box .wrapper {
        padding: 5px 0;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
    }
    .top-footer .wrapper .custom_heading {
        margin: 0 auto;
    }
}