/* RightNav.css */
.right-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #7978cb;
    overflow-x: hidden;
    transition: 0.3s;
    padding-top: 60px;
}

.right-nav a {
    padding: 10px 20px;
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    transition: 0.3s;
}

.right-nav a:hover {
    background-color: #cdc7c7;
}

.right-nav .close-btn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.right-nav.open {
    width: 250px;
}
