:root {
	--primary: #285D9F;
    --secondary: #E93D3D;
	--white: #fff;
	--font-14: 14px;
    --theme-black: #555;
    --theme-form-txt-color: #333;
    --title-font: 'Bona Nova SC';
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter/Inter-Bold.woff2') format('woff2'),
        url('../fonts/inter/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter/Inter-Medium.woff2') format('woff2'),
        url('../fonts/inter/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter/Inter-Light.woff2') format('woff2'),
        url('../fonts/inter/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter/Inter-Regular.woff2') format('woff2'),
        url('../fonts/inter/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/inter/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bona Nova SC';
    src: url('../fonts/bona/BonaNovaSC-Regular.woff2') format('woff2'),
        url('../fonts/bona/BonaNovaSC-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


body {
    font-family: 'Inter';
}
.title-font {
    font-family: 'Bona Nova SC';
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
}

.top-header {
	background-color: #fff;
	color: #000;
	font-size: var(--font-14);
}
.info-top-left {
    padding: 15px 0;
}
.info-top-right {
    padding: 15px 0;
    float: right;
    text-align: left;
}
.logo {
    float: left;
    max-width: 265px;
}
.navbar-brand {
    max-width: 265px;
    display: block;
    padding: 0;
}
.navbar-brand img {
    max-width: 100%;
    height: 190px;
}
.info-top-right a {
	color: #000;
    text-decoration: none;
}
.info-top-right .fa {
    padding: 2px;
    font-size: 14px;
    width: 18.9px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
    color: #000;
}
.info-top-right .fa-facebook {
    background-color: #3B5998;
    color: var(--white);
}
.info-top-right .fa-linkedin {
    background-color: #007bb5;
    color: var(--white);
}
.info-top-right .fa-instagram {
	background-color: #8a3ab9;
    color: var(--white);
}
#mainnav .menu-item ul.sub-menu {
    text-align: left;
    position: absolute;
    left: 0;
    top: 150%;
    width: 250px;
    background-color: #1d2738;
    z-index: 9999;
    filter: alpha(opacity = 0);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding-left: 0;
    padding-top: 5px;
}
#mainnav ul li {
    position: relative;
}
#mainnav .menu-item:hover > ul.sub-menu {
    top: 100%;
    opacity: 1;
    visibility: visible;
}
#mainnav .menu-item ul.sub-menu li a {
    display: block;
    color:  var(--white);
    text-decoration: none;
    padding: 0 0 0 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 40px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: none;
}
#mainnav ul.sub-menu a.nav-link {
    line-height: normal;
}
#mainnav .menu-item ul.sub-menu li {
    display: block;
    border-bottom: 1px solid #dd9933;
}
.nav-wrap {
    float: right;
}
.info-top-left p, .info-top-right a {
	margin-bottom: 0;
	line-height: 30px;
}
#mainnav .menu li {
    display: inline-block;
    padding: 0 14px;
}
#mainnav .menu li a.nav-link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0;
    line-height: 100px;
}
#mainnav .menu li a.nav-link:hover {
    color: var(--secondary);
}
#mainnav.navbar-expand-lg {
    height: 150px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 6px 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: #bac6d4;
}
.top-footer {
    background-color: var(--primary);
}
.top-footer .custom_heading {
    font-size: 22px;
    color:  var(--white);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.top-footer .wrapper {
    padding: 30px 0;
}
.top-footer .col-sm-2 .column-inner {
    height: 100%;
}
.top-footer .btn-contact a {
    color:  var(--white);
    border-color: var(--secondary);
    background-color: var(--secondary);
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, .2) 0, rgba(255, 255, 255, .01) 100%);
    background-repeat: repeat-x;
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
}
.top-footer .col-sm-2 .wrapper {
    padding: 30px 0;
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.footer .footer_background {
    padding-top: 64px;
    padding-bottom: 74px;
    background-color: #353334;
    color:  var(--white);
}
.f-widgets .widget-title {
    color: #fd5;
    margin-bottom: 31px;
    letter-spacing: -0.5px;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    margin-top: 17px;
    padding-right: 32px;
    display: inline-block;
}
.f-widgets .widget-title:before {
    bottom: 7px;
    right: 11px;
    width: 12px;
    background-color:  var(--white);
    content: "";
    height: 1px;
    position: absolute;
    content: "";
}
.f-widgets .widget-title:after {
    width: 23px;
    height: 1px;
    position: absolute;
    content: "";
    bottom: 4px;
    right: 0;
    background-color: var(--white);
}
.f-widgets .menu {
    display: block;
    overflow: hidden;
    padding: 0;
    margin: 0;
    list-style: none;
}
.f-widgets .menu .fmenu-item {
    position: relative;
    padding-left: 14px;
    width: 100%;
    float: left;
}
.f-widgets .menu .fmenu-item a {
    line-height: 36px;
    font-size: 14px;
    letter-spacing: -0.5px;
    color:  var(--white);
    text-decoration: none;
}
.f-widgets .menu .fmenu-item:before {
    content: "\f0da";
    position: absolute;
    left: 0;
    top: 5px;
    font-family: 'FontAwesome';
}
.f-socials li {
    padding-left: 32px;
    position: relative;
}
.f-socials {
    padding: 0;
    margin: 0;
    list-style: none;
}
.f-socials li a {
    line-height: 36px;
    font-size: 14px;
    letter-spacing: -0.5px;
    text-decoration: underline;
    color:  var(--white);
    text-decoration: none;
}
.f-socials li a i {
    position: absolute;
    top: 5px;
    left: 0;
    font-size: 14px;
    letter-spacing: -0.5px;
    padding: 2px;
    width: 18.9px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
    color: var(--white);
}
.footer-bottom {
    padding: 27px 0;
    background-color: var(--primary);
    font-size: 14px;
    z-index: 1;
    position: relative;
}
.footer-bottom p {
    margin: 0 auto;
    text-align: center;
    color: var(--white);
}

/* Services page */

.page__banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../images/services1.jpg');
}

.services-wrapper .page__banner {
    background-image: url('../images/services-banner.jpg');
}

.job_sseking-wrapper .page__banner {
    background-image: url('../images/jobSeeking-banner.png');
}

.job_opening-wrapper .page__banner {
    background-image: url('../images/jobOpening-banner.png');
}

.about-wrapper .page__banner {
    background-image: url('../images/about-banner.jpg');
}

.contact_us-wrapper .page__banner {
    background-image: url('../images/contact_us-banner.jpg');
}

.page__banner .banner-content {
    padding: 35px 0;
}

.page__banner .title {
    font-size: 30px;
    font-weight: 600;
}

.page__banner .description {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.contact_us-wrapper .page__banner .title,
.contact_us-wrapper .page__banner .description {
    color: var(--white);
}

.content-wrapper {
    padding: 35px 0;
}

.two-col-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 30px;
}
.action_wrapper {
    margin-top: 20px;
}

.action_wrapper .hire-btn {
    border: 0;
    background-color: var(--primary);
    color: var(--white);
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
}

.action_wrapper .hire-btn:hover {
    background-color: var(--theme-black);
}
.grid-child .images__wrapper {
    /* margin: 35px auto; */
}

.slideDown-animate-wrapper title-font {
    text-align: center;
}

.row-title {
    font-size: 22px;
    color: var(--primary);
    text-align: center;
    font-weight: bold;
}

.row-description-wrapper {
    margin-top: 35px;
}

.row-description-wrapper p {
    text-align: justify;
    line-height: 36px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.grid-child .images__wrapper img {
    border-radius: 40px;
}

.content-wrapper .two-col-grid:not(:last-child) {
    margin-bottom: 35px;
}

.action_wrapper {
    margin-top: 20px;
}

.action_wrapper .primary-btn {
    border: 0;
    background-color: var(--primary);
    color: var(--white);
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
}

.action_wrapper .primary-btn:hover {
    background-color: var(--theme-black);
}

 /* .slideDown-animate-wrapper title-font {
    transform: translateY(-50px);
    opacity: 0;
    visibility: hidden;
    transition: all 1s;
}  */

.slideDown-animate-wrapper title-font.startAnimate {
    transform: initial;
    opacity: 1;
    visibility: visible;
}

.cv-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

.form-input {
    background-color: var(--white);
    box-sizing: border-box;
    border-radius: 2px;
    color: var(--theme-form-txt-color);
    display: block;
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 6px 10px;
    height: 38px;
    width: 100%;
    line-height: 1.3;
}

.cv-form :where(fieldset:nth-last-child(2), fieldset:nth-last-child(3)) {
    grid-area: span 1 / span 2;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.get_in_touch-form label,
.cv-form :where(fieldset:nth-last-child(2), fieldset:nth-last-child(3)) label {
    font-weight: 700;
    display: block;
    
}

.required-star {
    color: #ff0000;
}

.dec-checkbox-wrapper {
    font-size: 14px;
    font-weight: 400;
}

.form-submit {
    border: 0;
    background-color: var(--primary);
    color: var(--white);
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
}

.form-submit:hover {
    background-color: var(--theme-black);
}

.container .job-opening-table-wrapper:last-child {
    margin-top: 35px;
}

.job-opening-table {
    width: 100%;
    text-align: center;
    table-layout: fixed;
}

.job-opening-table th,
.job-opening-table td {
    padding: 0 10px;
}

.job-opening-table thead h2 {
    font-size: 18px;
    color: var(--primary);
    margin: 20px 0 10px;
}

.job-opening-table tbody strong {
    font-size: 13px;
    display: block;
    margin-bottom: 15px;
}

.job-opening-table tbody strong a {
    color: #000;
    text-decoration: none;
    animation: blinker 1s linear infinite;
}

.ul-wrapper {
    margin: 0 0 0 25px;
    padding: 0;
    list-style: disc;
}

.ul-wrapper li {
    text-align: justify;
    line-height: 36px;
    font-size: 16px;
    font-weight: 500;
}

.what_we_do-ul {
    margin-top: 15px;
}

.vision_mission-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    column-gap: 0;
    row-gap: 0;
    color: #fff;
}

.vision_mission-wrapper .row-description-wrapper {
    margin-top: 0;
}

.vision-wrapper,
.mission-wrapper {
    padding: 35px 15px;
    border-radius: 40px;
}

.vision-wrapper {
    background-color: var(--primary);
    margin-right: 14px;
}

.mission-wrapper {
    background-color: var(--secondary);
}

.vision-wrapper .title,
.mission-wrapper .title {
    font-size: 22px;
    text-align: center;
}

.get-in-touch .row-description-wrapper:not(:last-child) {
    margin-bottom: 35px;
}

.get-in-touch .row-description-wrapper .title {
    color: var(--primary);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.get-in-touch .row-description-wrapper .sub-title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.get-in-touch .row-description-wrapper .address {
    font-size: 14px;
    color: #000;
    line-height: 25px;
}

.get-in-touch .row-description-wrapper .sub-title .contact {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 10px;
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

.get-in-touch .row-description-wrapper .sub-title .contact:hover {
    color: var(--secondary);
}

.contact_us-wrapper {
    margin-bottom: 5px !important;
    line-height: initial !important;
}

.address .mail {
    font-weight: 600;
    text-decoration: none;
    color: var(--primary);
}

.get_in_touch-form fieldset:not(:last-child) {
    margin-bottom: 15px;
}

.get_in_touch-form label {
    display: block;
    margin-bottom: 5px;
}

.get_in_touch-form input,
.get_in_touch-form select {
    background-color: var(--white);
    box-sizing: border-box;
    border-radius: 2px;
    color: var(--theme-form-txt-color);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: block;
    float: none;
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 6px 10px;
    height: 38px;
    width: 100%;
    line-height: 1.3;
    max-width: 60%;
}

.get_in_touch-form textarea {
    width: 100%;
    height: 120px;
    border: 1px solid #ccc;
}
.job_seeking_wrapper .cv-form-wrapper {
    margin-top: 30px;
}

.map-pad {
    margin: 20px;
}
.services-slider .flags {
    display: flex;
    padding: 5px;
    gap: 10px;
    margin-top: 6px;
}
.flags .sybox {
    background-color: #f5f5f5;
    border: 2px solid #ededed;
    padding: 5px;
    display: flex;
    flex: 0 0 200px;
    justify-content: space-between;
    width: 100%;
}
.flags .sybox>.row {
    display: flex;
    margin: 0;
    width: 100%;
}
.sybox>.row .col-4.col-md-4 {
    align-items: center;
    display: inline-flex;
    max-height: 50px;
    min-height: 50px;
}
.sybox>.row .col-4.col-md-4 img {
    max-width: 50px;
}
.sybox .my-auto {
    align-items: center;
    display: inline-flex;
    min-height: 50px;
}
.sybox .my-auto .txt_pri {
    font-size: 15px;
    margin: 0;
    text-transform: uppercase;
    font-family: 'Inter';
}

.brochure .col-2-grid.left {
    display: grid;
    grid-template-columns: 30% 66%;
    grid-gap: 4%;
}
.brochure .col-2-grid.right {
    display: grid;
    grid-template-columns: 66% 30%;
    grid-gap: 4%;
}
.brochure .image-grid .row1 {
    display: grid;
    grid-template-columns: 39% 29% 29%;
    grid-gap: 10px;
    padding-bottom: 10px;
}
.brochure .image-grid .row2 {
    display: grid;
    grid-template-columns: 29% 29% 39%;
    grid-gap: 10px;
    padding-bottom: 10px;
}
.brochure .image-grid .row3 {
    display: grid;
    grid-template-columns: 28% 41% 28%;
    grid-gap: 10px;
    padding-bottom: 10px;
}
.brochure .image-grid .row4 {
    display: grid;
    grid-template-columns: 41% 28% 28%;
    grid-gap: 10px;
    padding-bottom: 10px;
}
.brochure .image-grid .image {
    max-height: 140px;
    min-height: 140px;
}
.brochure .image-grid .image img {
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.brochure .col-2-grid.left .image-grid .row1 .image:last-child img {
    border-top-right-radius: 40px;
}
.brochure .col-2-grid.left .image-grid .row4 .image:first-child img {
    border-bottom-left-radius: 40px;
}
.brochure .col-2-grid.right .image-grid .row1 .image:first-child img {
    border-top-left-radius: 40px;
}
.brochure .col-2-grid.right .image-grid .row3 .image:last-child img {
    border-bottom-right-radius: 40px;
}
.brochure .col-2-grid .list li {
    font-size: 16px;
    line-height: 26px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.brochure .col-2-grid .list li svg {
    width: 14px;
    height: 14px;
    fill: #F14241;
    stroke: #F14241;
}
.brochure .col-2-grid .list ul {
    padding-left: 0;
    list-style-type: none;
}
.col-2-grid.left-grid {
    display: grid;
    grid-template-columns: 30% 66%;
    grid-gap: 4%;
}
.col-2-grid.right-grid {
    grid-template-columns: 66% 30%;
    display: grid;
    grid-gap: 4%;
}
.col-2-grid.left-grid .image-grid .ig-4,
.col-2-grid.right-grid .image-grid .ig-4 {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-gap: 10px;
}
.col-2-grid.left-grid .image-grid .ig-4 .image, .col-2-grid.left-grid .image-grid .ig-4 .image img,
.col-2-grid.right-grid .image-grid .ig-4 .image, .col-2-grid.right-grid .image-grid .ig-4 .image img {
    min-height: 140px;
    height: 100%;
}
.brochure .image-grid.row-3 .row2 {
    grid-template-columns: 32.33% 32.33% 32.33%;
}
.col-2-grid.left-grid .image-grid .ig-4.row1 .image:last-child img {
    border-top-right-radius: 40px;
}
.col-2-grid.left-grid .image-grid .ig-4.row4 .image:first-child img,
.col-2-grid .image-grid.row-3 .row3.ig-3 .image:first-child img {
    border-bottom-left-radius: 40px;
}
.col-2-grid.right-grid .image-grid .ig-4.row1 .image:first-child img {
    border-top-left-radius: 40px;
}
.col-2-grid.right-grid .image-grid .ig-4.row4 .image:last-child img {
    border-bottom-right-radius: 40px;
}

.info-top-right .fa-whatsapp {
    background-color: #2abf35;
    color: var(--white);
}
@media (max-width: 1199px) {
    .main-header .logo, .main-header .logo .navbar-brand {
        max-width: 200px;
        margin: 0;
    }
    #mainnav .menu li {
        display: inline-block;
        padding: 0 10px;
    }
    .f-widgets .widget-title {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .main-header.top-header .content-right {
        display: none;
    }
    .brochure .col-2-grid {
        gap: 3%;
    }
    .brochure .image-grid .image {
        max-height: 140px;
        min-height: 140px;
    }
    .brochure .image-grid .image img {
        height: 100%;
    }
}

@media (max-width: 991px) {
    .main-header button.navbar-toggler {
        border: 0;
    }
}
@media (max-width: 767px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
    .top-header .content-right {
        max-width: 35%;
    }
    .top-header .content-left {
        max-width: 65%;
        float: left;
    }
    .f-widgets .menu  {
        margin-bottom: 20px;
    }

    .two-col-grid {
        grid-template-columns: 100%;
    }

    .get_in_touch-form input,
    .get_in_touch-form select {
        max-width: 100%;
    }
}

@media (max-width: 568px) {
    .top-header .content-right, .top-header .content-left {
        max-width: 100%;
        text-align: center;
    }
    .top-header .info-top-right {
        padding: 15px 0;
        padding-top: 0;
        float: none;
        text-align: center;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}